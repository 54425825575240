<template>
  <b-container>
    <div class="text-center mb-2">
      <h3 class="text-primary">¡Prepárate para el viaje! Elige tu asiento en el autobús.</h3>
    </div>
    <b-row>
      <b-col cols="12" sm="5">
        <div class="bus-style-front"></div>
        <div class="bus-style-1 py-1 px-1">
          <table class="mx-0 my-0">
            <tr v-for="idxr, r in rows">
              <td v-for="idxc, c in cols" class="pl-1" style="width: 50px;">
                <svg
                  v-if="!isAisle(r, c) && getSeat(r,c)['Type'] == 1"
                  id="Layer_1"
                  data-name="Layer 1"
                  @click="onSeatSelected(r, c)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  transform="rotate(90)"
                >
                  <path :class="classifier(r, c)"
                    d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                  <path :class="classifier(r, c)"
                    d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                  <path :class="classifier(r, c)"
                    d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                  <path :class="classifier(r, c)"
                    d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                  <path :class="classifier(r, c)"
                    d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
                    <text
                      x="48%"
                      y="55%"
                      text-anchor="middle"
                      fill="black"
                      font-size="45px"
                      transform="rotate(-90, 50, 50)"
                    >
                      {{ getSeat(r,c)['Label'] }}
                    </text>
                </svg>
                <svg
                  v-else-if="getSeat(r,c)['Type'] == 2"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px"
                  viewBox="0 0 200 176"
                  style="enable-background:new 0 0 200 176;" xml:space="preserve"
                >
                  <g>
                    <path class="st0 cls-bloqueo" d="M185.23,176H14.77C6.61,176,0,169.39,0,161.23V14.77C0,6.61,6.61,0,14.77,0h170.46
                      C193.39,0,200,6.61,200,14.77v146.46C200,169.39,193.39,176,185.23,176z"/>
                    <g id="Grupo_304" transform="translate(12.245 10.911)">
                      <path id="Trazado_440" class="st1" d="M70.85,61.02c-0.9-1.6-2.57-2.6-4.4-2.64H49.28c-1.88-0.15-3.65,0.91-4.4,2.64l-7.93,22.9
                        c-0.88,1.61-0.29,3.64,1.33,4.52c0.27,0.15,0.57,0.26,0.87,0.33c1.8,0.8,3.9-0.02,4.7-1.82c0.05-0.12,0.1-0.25,0.14-0.38l2.2-4.84
                        v33.46c0,2.68,2.17,4.84,4.84,4.84c2.68,0,4.84-2.17,4.84-4.84v-20.7h1.32v20.7c0,2.68,2.16,4.85,4.84,4.85
                        c2.68,0,4.85-2.16,4.85-4.84c0-0.01,0-0.01,0-0.01V80.83l2.2,4.84c0.72,1.94,2.88,2.93,4.82,2.21c0.01,0,0.02-0.01,0.03-0.01
                        c1.65-1.02,2.52-2.93,2.2-4.84L70.85,61.02z"/>
                      <path id="Trazado_441" class="st1" d="M68.65,45.16c0,5.84-4.73,10.57-10.57,10.57c-5.84,0-10.57-4.73-10.57-10.57
                        s4.73-10.57,10.57-10.57c5.48-0.36,10.21,3.79,10.57,9.26C68.68,44.29,68.68,44.72,68.65,45.16z"/>
                      <path id="Trazado_442" class="st1" d="M128.1,44.72c0,5.84-4.73,10.57-10.57,10.57c-5.84,0-10.57-4.73-10.57-10.57
                        s4.73-10.57,10.57-10.57C123.33,34.23,128.02,38.92,128.1,44.72z"/>
                      <path id="Trazado_443" class="st1" d="M138.67,83.47l-9.25-22c-0.88-0.88-0.88-2.64-2.64-2.64h-16.29c-2.2,0-2.64,1.32-3.52,2.64
                        l-9.25,21.58c-1.03,1.67-0.51,3.86,1.16,4.89c1.67,1.03,3.86,0.51,4.89-1.16c0.04-0.06,0.08-0.13,0.11-0.2v-0.88l0,0
                        c-0.88,2.64-2.2,10.57-2.2,10.57c-0.06,1.4,1.02,2.58,2.42,2.64c0.07,0,0.14,0,0.22,0h4.4v16.28c0,2.64,1.32,4.4,3.52,4.4
                        s3.52-1.32,3.52-4.4V98.9h0.88v16.29c0,2.64,1.32,4.4,3.52,4.4s3.52-1.32,3.52-4.4V98.9h4.4c1.42-0.1,2.55-1.23,2.64-2.64
                        c0,0-1.32-9.25-1.32-11.45c0-0.88,0.88,1.32,0.88,1.32c0.88,1.32,2.64,2.64,4.4,1.32c3.96,0,4.84-3.52,3.96-3.96L138.67,83.47z"/>
                      <path id="Trazado_444" class="st1" d="M88.03,118.25c-0.04,0.71-0.61,1.28-1.32,1.32h-0.88c-0.71-0.04-1.28-0.61-1.32-1.32V36.79
                        c0.04-0.71,0.61-1.28,1.32-1.32h0.88c0.71,0.04,1.28,0.61,1.32,1.32V118.25z"/>
                    </g>
                  </g>
                </svg>
              </td>
            </tr>
          </table>
        </div>
        <div class="bus-style-back"></div>
      </b-col>
      <b-col cols="12" sm="7">
        <b-list-group>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-libre"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-libre"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-libre"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-libre"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-libre"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla disponible</span>
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-selected"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-selected"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-selected"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-selected"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-selected"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla seleccionada</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-vendida"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-vendida"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-vendida"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-vendida"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-vendida"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla ocupada</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-bloqueo"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-bloqueo"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-bloqueo"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-bloqueo"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-bloqueo"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla bloqueada</span>
          </b-list-group-item>
        </b-list-group>
        <hr>
        <b-list-group flush>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>Tipo de Autobús</span>
              <b-badge
                variant="primary"
                pill
                class="badge-round"
              >
                {{ tipobus }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        <hr>
        <h6 class="mt-2 text-primary">Sillas seleccionadas</h6>
        <b-alert
          v-if="selectedSeat.length == 0"
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25"> ¡Parece que no has seleccionado ninguna silla aún! Por favor, elige tus sillas para continuar.</span>
          </div>
        </b-alert>
        <b-list-group flush>
          <b-list-group-item
            v-for="(seat, key) in selectedSeat"
            :key="key"
            class="d-flex justify-content-between align-items-center"
          >
            <span><b>Silla</b> {{seat.Label}}</span>
            <span>${{ setPricing(seat) }}</span>
          </b-list-group-item>
          <b-list-group-item
            v-if="selectedSeat.length > 0"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bolder">Subtotal</span>
            <span>${{ setPricingSubTotal(selectedSeat) }}</span>
          </b-list-group-item>
        </b-list-group>
        <b-alert
          v-if="selectedSeat.length >= 6"
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25"> Has alcanzado el tope máximo de sillas permitidas por este operador en una sola transacción.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import {
  BCard, BCardTitle, BCardHeader, BCardBody, BLink, BRow, BCol,
  BContainer, BListGroup, BListGroupItem, BBadge, BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    BContainer,
    BListGroup,
    BListGroupItem,
    BBadge,
    BAlert
  },
  props: {
    rows: {
      type: Number,
      required: false,
      default: 0
    },
    cols: {
      type: Number,
      required: false,
      default: 0
    },
    cell: {
      type: Array,
      required: false,
      default: []
    },
    tarifa: {
      type: Object,
      required: false,
      default: ""
    },
    tipobus: {
      type: String,
      required: false,
      default: ""
    },
    travelData: {
      type: Object,
      required: true,
      default: () => { },
    },
  },
  data() {
    return {
      seats: [],
      selectedSeat: [],
    }
  },
  mounted() {
    this.generateSeats()
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
      {
        currency: 'COP',
      }).format(price)
    },
    getSeat(r, c) {
      for (let i = 0; i < this.cell.length; ++i) {
        if (this.cell[i].Fila == r && this.cell[i].Columna == c) {
          return this.cell[i];
        }
      }
      return null;
    },
    generateSeats() {
      this.cell.forEach(element => {
        if (!this.isAisle(element.Fila, element.Columna)) {
          this.seats.push({
            position: { r: element.Fila, c: element.Columna },
            status: element.Estado,
            label: element.Label,
          });
        }
      });
    },
    classifier(r, c) {
      let seat = this.getSeat(r, c)
      if (seat != null) {
        let position = this.selectedSeat.findIndex(row => row.Fila == r && row.Columna == c)
          if(position === -1) {
            switch (seat.Estado) {
              case '-1':
                return 'cls-libre';
              case '0':
                return 'cls-vendida';
              case '1':
                return 'cls-reservada';
              case '2':
                return 'cls-bloqueo';
              case '3':
                return 'cls-preferencial';
              case '4':
                return 'cls-discapacitados';
              case '7':
                return 'cls-bloqueo';
              default:
                return 'cls-libre';
            }
        } else {
          return 'cls-selected';
        }
      }
    },
    isAisle(r, c) {
      let getSeatOnly = this.getSeat(r, c)
      if(getSeatOnly.Type == 0 || getSeatOnly.Type == 3 || getSeatOnly.Type == 5 || getSeatOnly.Type == 6) {
        return true
      }
      return false;
    },
    async onSeatSelected(r, c) {
      let seat = this.getSeat(r, c)
      let selectIndex = this.selectedSeat.findIndex(row => row.Fila == r && row.Columna == c)
      if (seat.Estado === "-1" && this.selectedSeat.length <= 5 && seat.Type != 2) {
        if (selectIndex === -1) {
          this.selectedSeat.push(seat)
        } else {
          this.selectedSeat.splice(selectIndex, 1)
        }
      } else if(selectIndex != -1) {
        this.selectedSeat.splice(selectIndex, 1)
      }
      this.$emit('total-seat', {
        selected: this.selectedSeat,
        total: this.selectedSeat.length,
        pricingTotal: this.setPricingTotal(this.selectedSeat)
      })
    },
    setPricing(value) {
      const pricingSilla = Array.isArray(this.tarifa) ? this.tarifa : [this.tarifa];
      let pricing = pricingSilla.filter(element => element.Categoria == value.CategoriaID).map((row) => row['@attributes'])[0]
      return this.formatPrice(pricing.PrecioOneWay)
    },
    setPricingSubTotal(value) {
      let subTotal = 0
      const pricingSilla = Array.isArray(this.tarifa) ? this.tarifa : [this.tarifa];
      value.forEach(row => {
        subTotal = subTotal + parseFloat(pricingSilla.filter(element => element.Categoria == row.CategoriaID).map((row) => row['@attributes'])[0]['PrecioOneWay'])
      })
      return this.formatPrice(subTotal)
    },
    setPricingTotal(value) {
      let subTotal = 0
      const pricingSilla = Array.isArray(this.tarifa) ? this.tarifa : [this.tarifa];
      value.forEach(row => {
        subTotal = subTotal + parseFloat(pricingSilla.filter(element => element.Categoria == row.CategoriaID).map((row) => row['@attributes'])[0]['PrecioOneWay'])
      })
      return subTotal
    }
  }
}

</script>

<style>
  .cls-selected {
    fill: gray;
    stroke: #000;
    stroke-miterlimit: 10;
  }

  .cls-libre {
    fill: #fff;
    stroke: #000;
    stroke-miterlimit: 10;
  }

  .cls-vendida {
    fill: red;
    stroke: #000;
    stroke-miterlimit: 10;
  }

  .cls-reservada {
    fill: green;
    stroke: #000;
    stroke-miterlimit: 10;
  }

  .cls-bloqueo {
    fill: royalblue;
    stroke: blue;
    stroke-miterlimit: 10;
  }

  .cls-preferencial {
    fill: yellow;
    stroke: black;
    stroke-miterlimit: 10;
  }

  .cls-discapacitados {
    fill: #fff;
    stroke: blue;
    stroke-miterlimit: 10;
  }

  .bus-style-1 {
    /* border-radius: 3em/8em; */
    border-left: 2px solid #d3d9e6;
    border-right: 2px solid #d3d9e6;
  }
  .bus-style-front {
    background-image: url("~@/assets/images/svg/bus_front_1floor_new.svg");
    width: 100%;
    min-height: 92px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    border: 0;
    /* transform: rotate(270deg) */
  }
  .bus-style-back {
    background-image: url("~@/assets/images/svg/bus_back_new.svg");
    width: 100%;
    min-height: 92px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    border: 0;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>