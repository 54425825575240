<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <b-card>
        <b-card-text class="text-center">
          <content-loader
            view-box="0 0 250 110"
            :speed="2"
            primary-color="#15284861"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="3"
              ry="3"
              width="150"
              height="20"
            />
            <rect
              x="0"
              y="30"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="60"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="90"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="120"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
          </content-loader>
        </b-card-text>
        <hr>
        <b-card-text class="text-center">
          <content-loader
            view-box="0 0 250 110"
            :speed="2"
            primary-color="#15284861"
            secondary-color="#ecebeb"
          >
            <rect
              x="0"
              y="0"
              rx="3"
              ry="3"
              width="150"
              height="20"
            />
            <rect
              x="0"
              y="30"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="60"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="90"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
            <rect
              x="0"
              y="120"
              rx="3"
              ry="3"
              width="400"
              height="20"
            />
          </content-loader>
        </b-card-text>
      </b-card>
    </b-col>
    <b-col
      cols="12"
      md="9"
    >
      <div class="text-right">
        <h3 class="text-primary">
          <b-spinner type="grow" />
          Buscando opciones de viaje para ti, un momento por favor...
        </h3>
      </div>
      <b-row>
        <b-col>
          <b-card class="mb-0">
            <b-card-text class="text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="20"
                  y="0"
                  rx="3"
                  ry="3"
                  width="380"
                  height="20"
                />
                <rect
                  x="20"
                  y="40"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="60"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="80"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="100"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="120"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
              </content-loader>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="mb-0">
            <b-card-text class="text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="20"
                  y="0"
                  rx="3"
                  ry="3"
                  width="380"
                  height="20"
                />
                <rect
                  x="20"
                  y="40"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="60"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="80"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="100"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="120"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
              </content-loader>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="mb-0">
            <b-card-text class="text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="20"
                  y="0"
                  rx="3"
                  ry="3"
                  width="380"
                  height="20"
                />
                <rect
                  x="20"
                  y="40"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="60"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="80"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="100"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="120"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
              </content-loader>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="mb-0">
            <b-card-text class="text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="20"
                  y="0"
                  rx="3"
                  ry="3"
                  width="380"
                  height="20"
                />
                <rect
                  x="20"
                  y="40"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="60"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="80"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="100"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="120"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
              </content-loader>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col>
          <b-card class="mb-0">
            <b-card-text class="text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="20"
                  y="0"
                  rx="3"
                  ry="3"
                  width="380"
                  height="20"
                />
                <rect
                  x="20"
                  y="40"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="60"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="80"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="100"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
                <rect
                  x="20"
                  y="120"
                  rx="3"
                  ry="3"
                  width="380"
                  height="10"
                />
              </content-loader>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <div
        v-for="(n, key) in 3"
        :key="key"
        class="ecommerce-application"
      >
        <div class="list-view">
          <b-card
            class="ecommerce-card"
            no-body
          >
            <!-- Product Image -->
            <div class="item-img">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                width="500"
                height="300"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <path d="M484.52,64.61H15.65C7.1,64.61.17,71.2.17,79.31V299.82c0,8.12,6.93,14.7,15.48,14.7H484.52c8.55,0,15.48-6.58,15.48-14.7V79.31C500,71.2,493.07,64.61,484.52,64.61Zm-9,204.34c0,11.84-7.14,21.44-15.94,21.44H436.39L359.16,171.52c-7.1-10.92-19.67-11.16-27-.51L258.64,277.94C253.78,285,245.73,286,240,280.2l-79.75-80.62c-6-6.06-14.33-5.7-20,.88L62.34,290.39H40.63c-8.8,0-15.94-9.6-15.94-21.44V110.19c0-11.84,7.14-21.44,15.94-21.44H459.54c8.8,0,15.94,9.6,15.94,21.44Z" />
                <ellipse
                  cx="120"
                  cy="140"
                  rx="28"
                  ry="28"
                />
              </content-loader>
            </div>

            <b-card-body>
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="15"
                  y="15"
                  rx="4"
                  ry="4"
                  width="130"
                  height="10"
                />
                <rect
                  x="155"
                  y="15"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="295"
                  y="15"
                  rx="3"
                  ry="3"
                  width="90"
                  height="10"
                />
                <rect
                  x="15"
                  y="50"
                  rx="3"
                  ry="3"
                  width="90"
                  height="10"
                />
                <rect
                  x="115"
                  y="50"
                  rx="3"
                  ry="3"
                  width="60"
                  height="10"
                />
                <rect
                  x="185"
                  y="50"
                  rx="3"
                  ry="3"
                  width="200"
                  height="10"
                />
                <rect
                  x="15"
                  y="90"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="160"
                  y="90"
                  rx="3"
                  ry="3"
                  width="120"
                  height="10"
                />
                <rect
                  x="290"
                  y="90"
                  rx="3"
                  ry="3"
                  width="95"
                  height="10"
                />
                <rect
                  x="15"
                  y="130"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="160"
                  y="130"
                  rx="3"
                  ry="3"
                  width="225"
                  height="10"
                />
              </content-loader>
            </b-card-body>

            <!-- Product Options/Actions -->
            <div class="item-options text-center">
              <content-loader
                view-box="0 0 250 110"
                :speed="2"
                primary-color="#15284861"
                secondary-color="#ecebeb"
              >
                <rect
                  x="15"
                  y="15"
                  rx="4"
                  ry="4"
                  width="130"
                  height="10"
                />
                <rect
                  x="155"
                  y="15"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="295"
                  y="15"
                  rx="3"
                  ry="3"
                  width="90"
                  height="10"
                />
                <rect
                  x="15"
                  y="50"
                  rx="3"
                  ry="3"
                  width="90"
                  height="10"
                />
                <rect
                  x="115"
                  y="50"
                  rx="3"
                  ry="3"
                  width="60"
                  height="10"
                />
                <rect
                  x="185"
                  y="50"
                  rx="3"
                  ry="3"
                  width="200"
                  height="10"
                />
                <rect
                  x="15"
                  y="90"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="160"
                  y="90"
                  rx="3"
                  ry="3"
                  width="120"
                  height="10"
                />
                <rect
                  x="290"
                  y="90"
                  rx="3"
                  ry="3"
                  width="95"
                  height="10"
                />
                <rect
                  x="15"
                  y="130"
                  rx="3"
                  ry="3"
                  width="130"
                  height="10"
                />
                <rect
                  x="160"
                  y="130"
                  rx="3"
                  ry="3"
                  width="225"
                  height="10"
                />
              </content-loader>
            </div>
          </b-card>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import {
  BCard, BCardText, BCardBody, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import { ContentLoader } from 'vue-content-loader'

export default {

  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BCardBody,
    BSpinner,
    ContentLoader,
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
