<template>
  <div>
    <h4 class="font-lg font-weight-bolder">Empresas</h4>
    <b-form-group>
      <b-form-checkbox
        v-for="option in optionsCompany"
        :key="option.text"
        v-model="selectedCompany"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
    <hr>
    <h4  class="font-lg font-weight-bolder">Tipo de Autobús</h4>
    <b-form-group>
      <b-form-checkbox
        v-for="option in optionsTypebus"
        :key="option.text"
        v-model="selectedTypeBus"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    travels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCompany: [],
      selectedTypeBus: [],
      optionsCompany: [],
      optionsTypebus: [],
    }
  },
  watch: {
    selectedCompany(value) {
      this.$emit('companies-filter', value)
    },
  },
  async mounted() {
    this.optionsCompany = await this.companiesValues(this.travels)
    this.optionsTypebus = await this.typeBusValues(this.travels)
  },
  methods: {
    companiesValues(data) {
      const companies = []
      // eslint-disable-next-line
      for (const row in data) {
        const index = companies.findIndex(object => object.value === data[row].empresa)
        if (index < 0) {
          companies.push({
            text: data[row].empresa,
            value: data[row].empresa,
          })
        }
      }
      return companies
    },
    typeBusValues(value) {
      const typeBus = []
      // eslint-disable-next-line
      for (const row in value) { // eslint-disable-next-line
        const tarifas = Array.isArray(value[row].tarifa) ? value[row].tarifa : [value[row].tarifa];
        const index = typeBus.findIndex(object => object.value === tarifas[0]['@attributes'].CategoriaNombre) // eslint-disable-next-line
        if (index < 0) {
          typeBus.push({
            text: tarifas[0]['@attributes'].CategoriaNombre,
            value: tarifas[0]['@attributes'].CategoriaNombre,
          })
        }
      }
      return typeBus
    },
  },
}

</script>
