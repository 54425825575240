<template>
  <div class="ecommerce-application">
    <div class="list-view">
      <b-card
        class="ecommerce-card"
        no-body
      >
        <div class="item-img">
          <b-link>
            <slot name="img-logo" />
          </b-link>
        </div>
        <b-card-body>
          <b-row>
            <b-col
              cols="12"
              sm="3"
            >
              <div class="data-ticket">
                <h3 class="font-lg">Salida</h3>
                <h2 class="font-2xl">
                  {{ getFormattedTime(travel['FechaPartida']) }}
                </h2>
                <h5 class="font-lg">{{ travel['LocalidadOrigenNombre'] }}</h5>
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="3"
              align-self="center"
              class="py-2"
            >
              <span
                class="duration-travel font-xs"
              >
                {{ setDateAprox(travel['FechaPartida'], travel['FechaArribo'] ) }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="3"
            >
              <div class="data-ticket">
                <h3 class="font-lg">LLegada</h3>
                <h2 class="font-2xl">
                  {{ getFormattedTime(travel['FechaArribo']) }}
                </h2>
                <h5 class="font-lg">{{ travel['LocalidadDestinoNombre'] }}</h5>
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="3"
            >
              <div class="data-ticket">
                <h6 class="font-sm">Tipo de Autobús</h6>
                <b-badge
                  variant="primary"
                  pill
                  class="badge-round"
                >
                {{ setTypeBus(travel['tarifa']) }}
                </b-badge>
              </div>
            </b-col>
            <b-col
              cols="12"
            >
              <vue-policies
                :policies="travel.policy"
                :id-viaje="travel.idViaje"
              />
            </b-col>
          </b-row>
        </b-card-body>

        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <div class="data-ticket">
                <h3 class="font-lg">Desde</h3>
                <h2>${{ setPricing(travel['tarifa']) }}</h2>
                <h5 class="font-base">*Aplican costos adicionales </h5>
              </div>
            </div>
          </div>
          <b-button
            variant="primary"
            class="btn-cart move-cart"
            @click="setButacas(travel)"
          >
            <span class="text-nowrap">Seleccionar sillas</span>
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BLink, BRow, BCol, BButton, BBadge,
} from 'bootstrap-vue'
import Policies from '@/components/SearchTicket/Ticket/policies.vue'
import moment from 'moment'
import 'moment-precise-range-plugin'
import 'moment/locale/es'

export default {
  components: {
    BCard,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BBadge,
    BButton,
    'vue-policies': Policies,
  },
  // eslint-disable-next-line
  props: ['travel'],
  methods: {
    getFormattedTime(dateString) {
      // Establece moment para el formato adecuado y en español
      moment.locale('es');
      const date = moment(dateString, 'DD/MM/YYYY HH:mm:ss');

      // Devuelve solo la hora en formato de 12 horas con AM/PM
      return date.format('h:mm A'); // e.g., "3:00 PM"
    },
    setDateAprox(start, end) {
      const endTime = moment(end, 'DD-MM-YYYY H:mm:ss')
      const startTime = moment(start, 'DD-MM-YYYY H:mm:ss').preciseDiff(endTime, true)
      return `${startTime.hours}H (Aprox)`
    },
    setButacas(travel) {
      this.$emit('travel-butacas', travel)
    },
    setPricing(value) {
      const travels = Array.isArray(value) ? value : [value]
      return this.formatPrice(parseFloat(travels[0]['@attributes'].PrecioOneWay))
    },
    setTypeBus(value) {
      const travels = Array.isArray(value) ? value : [value]
      return travels[0]['@attributes'].CategoriaNombre
    },
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
        {
          currency: 'COP',
        }).format(price)
    },
  },
}

</script>
