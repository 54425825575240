<template>
  <b-row>
    <b-col cols="12">
      <div>
        <h3 class="text-primary">
          <b-spinner type="grow" />
          ¡Estamos en ello! Buscando las butacas perfectas para tu comodidad. Esto tomará solo un momento
        </h3>
      </div>
      <div
        v-for="(n, key) in 3"
        :key="key"
        class="ecommerce-application"
      >
        <b-card
          class="ecommerce-card"
          no-body
        >
          <b-card-body>
            <content-loader
              view-box="0 0 250 110"
              :speed="2"
              primary-color="#15284861"
              secondary-color="#ecebeb"
            >
              <rect
                x="15"
                y="15"
                rx="4"
                ry="4"
                width="130"
                height="10"
              />
              <rect
                x="155"
                y="15"
                rx="3"
                ry="3"
                width="130"
                height="10"
              />
              <rect
                x="295"
                y="15"
                rx="3"
                ry="3"
                width="90"
                height="10"
              />
              <rect
                x="15"
                y="50"
                rx="3"
                ry="3"
                width="90"
                height="10"
              />
              <rect
                x="115"
                y="50"
                rx="3"
                ry="3"
                width="60"
                height="10"
              />
              <rect
                x="185"
                y="50"
                rx="3"
                ry="3"
                width="200"
                height="10"
              />
              <rect
                x="15"
                y="90"
                rx="3"
                ry="3"
                width="130"
                height="10"
              />
              <rect
                x="160"
                y="90"
                rx="3"
                ry="3"
                width="120"
                height="10"
              />
              <rect
                x="290"
                y="90"
                rx="3"
                ry="3"
                width="95"
                height="10"
              />
              <rect
                x="15"
                y="130"
                rx="3"
                ry="3"
                width="130"
                height="10"
              />
              <rect
                x="160"
                y="130"
                rx="3"
                ry="3"
                width="225"
                height="10"
              />
            </content-loader>
          </b-card-body>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import {
  BCard, BCardBody, BRow, BCol, BSpinner,
} from 'bootstrap-vue'
import { ContentLoader } from 'vue-content-loader'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BSpinner,
    ContentLoader,
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>
